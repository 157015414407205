import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  setActiveDesignAsset,
  setActiveVariation,
} from '@/redux/slices/collection';
import '../editor.scss';
import {
  Text,
  Flex,
  Button,
  ScrollArea,
  AlertDialog,
  Box,
  Tooltip,
} from '@radix-ui/themes';
import { useState, useEffect } from 'react';
import { ReactComponent as PenBold } from '@/assets/icons/pen-bold.svg';
import { ReactComponent as PenLinearIcon } from '@/assets/icons/pen-linear.svg';
import { setActiveView, setActiveAction } from '@/redux/slices/editor';
import { psdEngineApi } from '@/services/psdEngine';
import Skeleton from 'react-loading-skeleton';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.svg';
import { ReactComponent as TrashBin } from '@/assets/icons/trash-bin.svg';
import useCaptureEvent from '@/hooks/useCaptureEvent';

interface IAiBackgrounds {}

export const AiBackgrounds = ({}: IAiBackgrounds) => {
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();
  const {
    designAssets,
    activeDesignAsset,
    activeColorCombination,
    activeSmartObject,
    activeVariation,
    mockupVariations,
    isCreatingVariations,
    colorCombinationsContainerHeight,
  } = useAppSelector((state) => state.collectionReducer);
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  const [numberOfDesignAssetsAdding, setNumberOfDesignAssetsAdding] =
    useState<number>(0);

  useEffect(() => {
    const activeVariationAssetId = activeVariation?.variation_elements.find(
      (ve) => ve.smart_object_id === activeSmartObject?.id
    )?.asset_id;

    dispatch(
      setActiveDesignAsset(
        designAssets[activeSmartObject?.id]?.find(
          (asset) => asset.id === activeVariationAssetId
        )
      )
    );
  }, [activeVariation, activeSmartObject, designAssets]);

  const changeActiveDesignAsset = (id: number) => {
    if (isCreatingVariations) {
      return;
    }

    dispatch(
      setActiveVariation(
        mockupVariations.find((mv) =>
          mv.variation_elements.find((ve) => ve?.asset_id === id)
        )
      )
    );
  };

  const [removeAsset, { isLoading: isDeletingAsset }] =
    psdEngineApi.useDeleteAssetVariationMutation();

  const [isAdding, setIsAdding] = useState<boolean>(false);

  const designAssetAction = async (action: 'add' | 'edit', daId?: number) => {
    if (isCreatingVariations) {
      return;
    }
    changeActiveDesignAsset(daId!);
    await new Promise((resolve) => setTimeout(resolve, 50));

    dispatch(setActiveView('update-asset'));
    dispatch(setActiveAction(action));
  };

  const getNumerOfAssociatedVariations = (id: number) => {
    const associatedVariations = mockupVariations.filter((variation) => {
      return variation.variation_elements.some(
        (element) => element.asset_id === id
      );
    });

    return associatedVariations.length;
  };

  const removeDesignAsset = async (id: number) => {
    await removeAsset({ id });
  };

  return (
    <Flex
      className="design-assets"
      direction={'column'}
      align={'start'}
      px={'4'}
    >
      {!isMobile && (
        <Flex className="info" direction={'column'} gap={'2'}>
          <Text weight={'medium'} size={'2'}>
            ✨ AI Backgrounds
          </Text>
          <Text weight={'regular'} size={'2'} color="gray">
            Generate unique scenes for your mockups
          </Text>
          {/* {designAssets?.[activeSmartObject?.id]?.length > 0 && (
            <AddDesignAssetDialog
              setNumberOfDesignAssetsAdding={(data) =>
                setNumberOfDesignAssetsAdding(data)
              }
              setIsAdding={(isAdding) => setIsAdding(isAdding)}
            >
              <Button
                variant="outline"
                size={'1'}
                style={{ width: '32px', height: '32px' }}
                color="gray"
                
                onClick={() =>
                   captureEvent('Upload Dessign Mockup modal viewed')
                }
              >
                <PlusIcon width={'24px'} height={'24px'} className="icon" />
              </Button>
            </AddDesignAssetDialog>
          )} */}
        </Flex>
      )}
      <ScrollArea
        type={isMobile ? 'always' : 'hover'}
        scrollbars={isMobile ? 'horizontal' : 'vertical'}
        className="assets-scroll-area"
        style={{
          maxHeight: `calc(100vh - 175px - ${colorCombinationsContainerHeight}px)`,
        }}
      >
        {!isMobile && (
          <Flex
            className="asset-items"
            wrap={'wrap'}
            pt={'4'}
            direction={'column'}
            style={{
              width: isMobile
                ? (designAssets?.[activeSmartObject?.id]?.length + 1) * 83 +
                  30 +
                  'px'
                : undefined,
            }}
          >
            {!isAdding &&
              designAssets?.[activeSmartObject?.id]?.length === 0 &&
              [...Array(1)].map((_, index) => (
                <Button
                  key={index}
                  className="new"
                  variant="surface"
                  color="gray"
                  onClick={() => {
                    dispatch(setActiveView('create-ai-background'));
                    dispatch(setActiveAction('add'));
                    captureEvent('Upload Dessign Mockup modal viewed');
                  }}
                >
                  <PlusIcon width={'24px'} height={'24px'} className="icon" />
                </Button>
              ))}

            {designAssets[activeSmartObject.id]
              ?.filter((da) => da.is_ai_generated)
              ?.map((da) => (
                <Flex
                  key={da.id}
                  className={`asset-item ${
                    da.id === activeDesignAsset?.id && 'active'
                  }`}
                  align={'center'}
                  justify={'between'}
                  id="asset-item"
                  p={'2'}
                  gap={'2'}
                  onClick={() => changeActiveDesignAsset(da.id)}
                >
                  <Flex gap={'2'} align={'center'}>
                    <LazyLoadImage
                      className="asset"
                      style={{
                        objectFit: 'contain',
                      }}
                      effect="blur"
                      src={da.asset_path}
                      delayTime={0}
                      placeholderSrc={da.asset_path}
                      wrapperProps={{
                        style: { transitionDelay: '0s' },
                      }}
                    />
                    <Tooltip content={da.name}>
                      <Text
                        size={'1'}
                        weight={'regular'}
                        className="asset-name"
                      >
                        {da.name}
                      </Text>
                    </Tooltip>
                  </Flex>
                  <Flex gap={'4'} align={'center'} pr={'3'} className="actions">
                    {/* <Button
                      variant="ghost"
                      color="gray"
                      id="edit-asset"
                      className="home__icon"
                      onClick={() => designAssetAction('edit', da.id)}
                      disabled={isCreatingVariations}
                    >
                      <PenLinearIcon
                        className="icon"
                        width={'16px'}
                        height={'16px'}
                      />
                    </Button> */}
                    <Box width={'32px'}></Box>

                    <AlertDialog.Root>
                      <AlertDialog.Trigger>
                        <Button
                          variant="ghost"
                          color="gray"
                          size={'1'}
                          disabled={isDeletingAsset || isCreatingVariations}
                        >
                          <TrashBin
                            className="icon"
                            width="18px"
                            height="18px"
                          />
                        </Button>
                      </AlertDialog.Trigger>
                      <AlertDialog.Content style={{ maxWidth: 450 }}>
                        <AlertDialog.Title>
                          Remove AI Background
                        </AlertDialog.Title>
                        <AlertDialog.Description size="2">
                          Are you sure you want to proceed? Removing this AI
                          Background will{' '}
                          {getNumerOfAssociatedVariations(da.id) ===
                          mockupVariations.length
                            ? 'update'
                            : 'remove'}{' '}
                          {getNumerOfAssociatedVariations(da.id)} associated{' '}
                          {getNumerOfAssociatedVariations(da.id) === 1
                            ? 'variation.'
                            : 'variations.'}
                        </AlertDialog.Description>

                        <Flex gap="3" mt="4" justify="end">
                          <AlertDialog.Cancel>
                            <Button variant="soft" color="gray">
                              Cancel
                            </Button>
                          </AlertDialog.Cancel>
                          <AlertDialog.Cancel>
                            <Button
                              variant="solid"
                              color="red"
                              onClick={() => removeDesignAsset(da.id)}
                            >
                              Remove
                            </Button>
                          </AlertDialog.Cancel>
                        </Flex>
                      </AlertDialog.Content>
                    </AlertDialog.Root>
                  </Flex>
                </Flex>
              ))}
            {isAdding &&
              [...Array(numberOfDesignAssetsAdding)].map((_, index) => (
                <Skeleton
                  key={index}
                  height={'48px'}
                  width={'100%'}
                  style={{
                    borderRadius: '8px',
                  }}
                />
              ))}
            {!isMobile && designAssets?.[activeSmartObject?.id]?.length > 0 && (
              <Button
                className="new"
                variant="surface"
                color="gray"
                onClick={() => {
                  dispatch(setActiveView('create-ai-background'));
                  dispatch(setActiveAction('add'));
                  captureEvent('Upload Dessign Mockup modal viewed');
                }}
              >
                <PlusIcon width={'24px'} height={'24px'} className="icon" />
              </Button>
            )}
          </Flex>
        )}
        {isMobile && (
          <Flex
            className="asset-items"
            wrap={'wrap'}
            pt={'4'}
            direction={'row'}
            style={{
              width: isMobile
                ? (designAssets?.[activeSmartObject?.id]?.length + 1) * 83 +
                  30 +
                  'px'
                : undefined,
            }}
          >
            <Button
              className="new"
              variant="surface"
              color="gray"
              onClick={() => {
                dispatch(setActiveView('create-ai-background'));
                dispatch(setActiveAction('add'));
                captureEvent('Upload Dessign Mockup modal viewed');
              }}
            >
              <PlusIcon width={'24px'} height={'24px'} className="icon" />
            </Button>
            {designAssets?.[activeSmartObject?.id]?.length === 0 &&
              [...Array(1)].map((_, index) => (
                <Button
                  key={index}
                  className="new"
                  variant="surface"
                  color="gray"
                  onClick={() => {
                    dispatch(setActiveView('create-ai-background'));
                    dispatch(setActiveAction('add'));
                    captureEvent('Upload Dessign Mockup modal viewed');
                  }}
                >
                  <PlusIcon width={'24px'} height={'24px'} className="icon" />
                </Button>
              ))}

            {designAssets[activeSmartObject.id]
              ?.filter((da) => da.is_ai_generated)
              ?.map((da) => (
                <Flex
                  key={da.id}
                  className={`asset-item ${
                    da.id === activeDesignAsset?.id && 'active'
                  }`}
                  align={'center'}
                  justify={'center'}
                  id="asset-item"
                >
                  <LazyLoadImage
                    onClick={() => changeActiveDesignAsset(da.id)}
                    className="asset"
                    style={{
                      objectFit: 'contain',
                    }}
                    effect="blur"
                    src={da.asset_path}
                    delayTime={0}
                    placeholderSrc={da.asset_path}
                    wrapperProps={{
                      style: { transitionDelay: '0s' },
                    }}
                  />
                  {/* <Flex className="edit" align={'center'} justify={'center'}>
                    <PenBold
                      width={'12px'}
                      height={'12px'}
                      className="home__icon"
                      onClick={() => designAssetAction('edit', da.id)}
                    />
                  </Flex> */}
                  <Box width={'32px'}></Box>
                </Flex>
              ))}
            {isAdding &&
              [...Array(numberOfDesignAssetsAdding)].map((_, index) => (
                <Skeleton
                  key={index}
                  height={'76px'}
                  width={'76px'}
                  style={{
                    borderRadius: '8px',
                  }}
                />
              ))}
          </Flex>
        )}
      </ScrollArea>
    </Flex>
  );
};
