import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { Box } from '@radix-ui/themes';
import { AppRoutes } from '@/routes';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { userApi } from './services/user';
import { workspaceApi } from './services/workspace';
import posthog from 'posthog-js';
import { Theme } from '@radix-ui/themes';
import { setThemeMode } from './redux/slices/app';
import { useFeatureFlagEnabled } from 'posthog-js/react';

export const App = () => {
  const dispatch = useAppDispatch();
  const { themeMode } = useAppSelector((state) => state.appReducer);
  const { currentUser } = useAppSelector((state) => state.userReducer);
  const { currentWorkspace } = useAppSelector(
    (state) => state.workspaceReducer
  );
  const { isAuthenticated } = useAppSelector((state) => state.authReducer);

  userApi.useGetCurrentUserQuery(null!, {
    skip:
      window.location.pathname.includes('login') ||
      window.location.pathname.includes('register') ||
      window.location.pathname.includes('forgot-password') ||
      !isAuthenticated,
  });
  workspaceApi.useGetCurentUserWorkspaceInfoQuery(null!, {
    skip:
      window.location.pathname.includes('login') ||
      window.location.pathname.includes('register') ||
      window.location.pathname.includes('forgot-password') ||
      !isAuthenticated,
  });
  const haveTrialProSubscription = useFeatureFlagEnabled(
    'trial-pro-subscription'
  );

  useEffect(() => {
    // identify posthog user

    if (
      currentWorkspace &&
      currentUser &&
      typeof haveTrialProSubscription !== 'undefined'
    ) {
      posthog.identify(currentUser?.email, {
        email: currentUser?.email,
        first_name: currentUser?.first_name,
        last_name: currentUser?.last_name,
        subscription_status: haveTrialProSubscription
          ? 'active'
          : currentUser?.subscription === 'paid'
          ? 'active'
          : 'inactive',
      });
    }
  }, [currentWorkspace, currentUser, haveTrialProSubscription]);

  useEffect(() => {
    const userPrefferedThemeMode = window.localStorage.getItem('app-theme');
    const systemTheme = window.matchMedia('(prefers-color-scheme: dark)')
      .matches
      ? 'dark'
      : 'light';

    dispatch(setThemeMode(userPrefferedThemeMode || systemTheme || 'light'));
    window.localStorage.setItem(
      'app-theme',
      userPrefferedThemeMode || systemTheme || 'light'
    );

    if (
      (userPrefferedThemeMode || systemTheme) !== 'light' &&
      (userPrefferedThemeMode || systemTheme) !== 'dark'
    ) {
      dispatch(setThemeMode('light'));
      window.localStorage.setItem('app-theme', 'light');
    }
  }, []);

  return (
    <Theme appearance={themeMode} accentColor="blue">
      <Box height={'100%'} style={{ maxWidth: '2560px', margin: '0 auto' }}>
        <RouterProvider router={AppRoutes} />
      </Box>
    </Theme>
  );
};

